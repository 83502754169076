<template>
  <div>
    <ClaimSuccessLoading v-if="isLoadingInProgress" />
    <StatusPage
      v-else
      status="success"
      class="depot-repair-success"
      :title="
        isLoadingInProgress
          ? ''
          : `Great news! You've successfully set up the Service Call for a Depot Service`
      "
      :show-status-icon="!isLoadingInProgress"
      restrict-title-width
    >
      <div class="depot-repair-success__container">
        <!-- <div class="depot-repair-success__location-container">
          <p class="depot-repair-success__text">
            Please drop off your device {{ jobInfo.deviceName }} packed in box with shipping label at:
            <b>{{ jobInfo.location.name }}</b>
            <GetDirections
              class="depot-repair-success__get-directions"
              :from="jobInfo.location.from"
              :to="jobInfo.location.to"
            />
          </p>
        </div> -->

        <div class="depot-repair-success__shipping-label-description">
          <p class="depot-repair-success__text">
            <strong>Here is your shipping label.{{ packingBoxMessage }}</strong>
            <br class="depot-repair-success__text-break" />
            <span>Please print and then affix label to the box.</span>
          </p>
          <!-- <p class="depot-repair-success__text depot-repair-success__text--notice">

          </p> -->
          <hs-button
            class="depot-repair-success__download-label"
            size="md"
            @click="downloadShippingLabel"
            >Download</hs-button
          >
        </div>

        <div class="depot-repair-success__shipping-label-container">
          <object
            class="depot-repair-success__shipping-label"
            aria-label="pdf"
            :data="labelHref"
          ></object>
        </div>

        <b class="depot-repair-success__note-text"
          >Note: Before shipping your device, please back it up and unlock it (or provide us with
          your access code/password).</b
        >
      </div>
    </StatusPage>
  </div>
</template>
<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import download from '@/utils/download';
// import GetDirections from '@/components/shared/GetDirections';
import StatusPage from '@/components/shared/StatusPage';
import fetchContractProductsMixin from '@/mixins/fetchContractProductsMixin';
import ClaimSuccessLoading from '@/components/shared/ClaimSuccessLoading';
import {allowDebug} from '@/constants/env';

export default {
  components: {
    StatusPage,
    // GetDirections,
    ClaimSuccessLoading,
  },
  mixins: [fetchContractProductsMixin],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState('deviceInformation', ['productLineName']),
    ...mapGetters('jobInfo', ['getByMasterId']),
    ...mapGetters('user', ['productLinesClaims']),
    texts() {
      return texts;
    },
    claimId() {
      return this.$route.params.id;
    },
    claim() {
      return this.getByMasterId(this.claimId);
    },
    labelHref() {
      let href = null;
      const claimDetails = this.claim?.claimDetails;

      if (claimDetails) {
        const {shippingLabelFromCustomer} = claimDetails;
        href = `data:application/pdf;base64,${shippingLabelFromCustomer}`;
      }

      return href;
    },
    deviceCategory() {
      const {productLineName, productLinesClaims} = this;
      return productLinesClaims[productLineName]?.featureCode;
    },
    packingBoxMessage() {
      const DAYS = {
        Tablet: 2,
        Computer: 5,
      };
      const days = DAYS[this.deviceCategory];
      return days ? ` You will receive a packing box within ${days} business days.` : '';
    },
    isLoadingInProgress() {
      const {deviceCategory, isLoading, claim} = this;
      return isLoading || !deviceCategory || !claim;
    },
  },
  watch: {
    claimId: {
      async handler() {
        this.isLoading = true;

        const {customerRef} = await this.DECRYPT_PARAMS({
          customerRef: this.$route.query.customerRef,
        });

        try {
          await this.createServifyClaim(customerRef);
        } finally {
          await this.GET_CLAIMS_LIST({customerRef});
          await this.$nextTick();

          await this.GET_DEVICE_INFO({
            customerRef,
            id: this.claim?.claimDetails?.inventoryEquipmentID,
          });

          this.isLoading = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('user', ['DECRYPT_PARAMS']),
    ...mapActions('jobInfo', ['GET_CLAIMS_LIST']),
    ...mapActions('deviceInformation', ['GET_DEVICE_INFO']),
    ...mapActions('requestService', ['START_SERVIFY_CLAIM', 'GET_LOCATION_BY_ADDRESS']),
    showClaimCreationError() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      const devicesLink = {
        name: 'MyDevices',
        query,
      };
      this.$store.dispatch('setModalError', {
        text: [
          'Seems like we have a trouble processing your request.',
          'Please contact the call center.',
        ],
        cta: [
          {
            text: 'Go to My Devices',
            callback: () => {
              console.log('Devices callback called:: ' + devicesLink);
              this.$store.commit('errorModal/CLEAR_ERROR_MODAL_DATA');
              this.$router.push(devicesLink);
            },
          },
        ],
      });
    },

    downloadShippingLabel() {
      download({
        src: this.labelHref,
        fileName: 'shipping-label',
      });
    },

    async createServifyClaim(customerRef) {
      await this.GET_CLAIMS_LIST({customerRef});

      const claim = this.claim;
      const claimDetails = this.claim?.claimDetails;
      const deviceId = claimDetails?.inventoryEquipmentID;
      const triageReferenceId = claimDetails?.triageReferenceID;
      const productSubCategoryCode = claim?.productLine;
      const issueCode = claim?.complaintCode;
      const issueRemarks = claim?.complainCodeName;
      const issueCategoryCode = claim?.issueCategoryCode;
      const serviceLocationCode = claim?.serviceProviderAccount;

      // TODO: Lost date should be less of equal to current date to be valid.
      // That might cause issues when client-side time is different from server-side timezone.
      const lossDateTime = claimDetails?.lossDateTime?.split('T')[0];
      const descriptionOfLoss = claimDetails?.descriptionOfLoss;
      const externalReferenceID = this.$route.params.id;
      const vendorCode = claimDetails.vendorCode;

      const _addressType = claim?.customerAddressType;
      const _city = claim?.customerCity;
      const _flatNo = claim?.customerFlatNo;
      const _houseNo = claim?.customerHouseNo;
      const _landmark = claim?.customerLandmark;
      const _state = claim?.customerState;
      const _address = claim?.customerClaimAddressLine1;
      const _street = claim?.customerStreetName;
      const _zipCode = claim?.customerPostalCode;

      const geoLocation = await this.GET_LOCATION_BY_ADDRESS({
        city: _city,
        flatNo: _flatNo,
        houseNo: _houseNo,
        landmark: _landmark,
        state: _state,
        address: _address,
        streetName: _street,
        zipcode: _zipCode,
      });

      const dropOffLocationCode = claimDetails?.dropOffLocationCode;
      const dropOffLocationName = claimDetails?.dropOffLocationName;
      const address = claimDetails?.dropOffLocationAddress;
      const pinCode = claimDetails?.dropOffLocationZipcode;
      const city = claimDetails?.dropOffLocationCity;
      const state = claimDetails?.dropOffLocationState;

      const servifyClaim = await this.START_SERVIFY_CLAIM({
        serviceTypeCode: 'CLAIM_DROPOFF',
        deviceId,
        productSubCategoryCode,
        customerRef,
        externalReferenceID,
        issueCode,
        issueRemarks,
        issueCategoryCode,
        lossDateTime,
        descriptionOfLoss,

        dropOffLocation: {
          dropOffLocationCode,
          dropOffLocationName,
          address,
          pinCode,
          city,
          state,
        },

        consumerServiceRequestParams: {
          externalReferenceID,
          triageReferenceId,
          serviceLocationCode,
          vendorCode,

          addressType: _addressType,
          city: _city,
          flatNo: _flatNo,
          houseNo: _houseNo,
          landmark: _landmark,
          state: _state,
          streetName: _street,
          StreetName: _street,
          address: _address,
          zipcode: _zipCode,

          lat: geoLocation?.latitude,
          lng: geoLocation?.longitude,
          latitude: geoLocation?.latitude,
          longitude: geoLocation?.longitude,
        },
      });

      if (servifyClaim?.errorDetails?.errorDetail?.errorDescription) {
        this.showClaimCreationError();
        if (allowDebug) {
          this.$toasted.show(servifyClaim.errorDetails.errorDetail.errorDescription);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.depot-repair-success {
  flex-grow: 0.65;
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 800px;
    padding-top: 24px;
    color: $darkGray;
    font-size: 18px;
  }

  &__location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    padding: 22px;
    background-color: #fcfbfb;
    border: 1px dashed $lightGray;
    border-radius: 4px;

    @include mf($gridMDBreakpoint) {
      width: 360px;
    }
  }
  &__get-directions {
    margin: 0 8px;
    font-size: 18px;
  }
  &__shipping-label-description {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 210px;
    text-align: left;

    @include mf($gridMDBreakpoint) {
      height: unset;
    }
  }
  &__text {
    line-height: 160%;
  }
  &__text-break {
    display: none;

    @include mf($gridMDBreakpoint) {
      display: inline;
    }
  }
  &__text-break-additional-text {
    display: inline;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__text--notice {
    padding: 7px 0 18px;
  }
  &__shipping-label-container {
    width: 130px;
  }
  &__shipping-label {
    align-self: flex-start;
    transform: rotate(90deg) scale(0.6) translateY(140px);
  }
  &__download-label {
    width: 131px;
    margin: 15px auto 0 auto;

    @include mf($gridMDBreakpoint) {
      margin: 15px 0 0 0;
    }
  }
  &__note-text {
    max-width: 510px;
    margin-top: 38px;
  }
}
</style>
